import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
// import { PublicClientApplication } from '@azure/msal-browser';
// import { MsalProvider } from '@azure/msal-react';
// import { msalConfig } from './components/authConfig';

// const msalInstance = new PublicClientApplication(msalConfig);

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <MsalProvider instance={msalInstance}> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </MsalProvider> */}
  </React.StrictMode>
);